<template>
  <div style="height: 100%;">
    <v-container v-show="!sendingDocument" fluid style="height: 100%;">
      <Camera @add-scan="addScan"
              @image-to-preview="setPreviewedImage"
              :current-images="Boolean(currentImages.length)"
              :bus="bus"
      />
      <ImagePreview :image="previewedImage"
                    :current-page="currentImages.length + 1"
                    :options="options"
                    @preview-closed="previewedImage = null"
                    @user-overlay-closed="discardDocument"
                    @new-page="addScanAndOpenNewPage(previewedImage)"
                    @send-image="sendDocuments(previewedImage)"/>
    </v-container>
    <v-container v-show="sendingDocument" fluid style="height: 100%">
      <v-row style="height: 100%">
        <v-col cols="12">
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="title text-center"
              cols="12"
            >
              {{ $t('Index.documentsSending')}}
            </v-col>
            <v-col cols="8" md="6">
              <v-progress-linear
                color="secondary accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import generatePDF from '@/helpers/pdf';
import Vue from 'vue';
import Camera from '@/components/Camera.vue';
import ImagePreview from '../components/ImagePreview.vue';
import axiosInstance from '../plugins/axios';

export default {
  name: 'Index',
  components: { ImagePreview, Camera },
  data() {
    return {
      showCamera: false,
      overlay: false,
      imageLoaded: false,
      currentImages: [],
      previewedImage: null,
      bus: new Vue(),
      sendingDocument: false,
      options: {
        documentType: 'INV'
      },
    };
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') this.$store.dispatch('user/refreshToken');
    });
  },
  methods: {
    addScanAndOpenNewPage(image) {
      this.addScan(image);
      this.bus.$emit('open-camera');
    },
    addScan(image) {
      if (!this.currentImages.some((img) => img.id === image.id)) {
        this.currentImages.push(image);
      }
      this.previewedImage = null;
    },
    async sendDocuments(image) {
      this.addScan(image);
      try {
        this.sendingDocument = true;
        const imageDataArray = this.currentImages.map((image) => image.dataURL);
        const pdfDocument = await generatePDF(imageDataArray);
        const formData = new FormData();
        formData.append('file', pdfDocument, 'scan.pdf');
        formData.append('document_type', this.options.documentType);
        await axiosInstance.post('uploaded-documents/', formData);
        this.$snackbar({
          timeout: 2500,
          content: this.$t('Index.documentSuccessfullySent'),
          color: 'success',
          icon: '$done'
        });
        this.currentImages = [];
        this.previewedImage = null;
        this.options = {
          documentType: 'INV'
        }
      } catch (err) {
        this.$sentry.withScope((scope) => {
          const { user } = this.$store.state.user;
          scope.setUser({ email: user.email });
          this.$sentry.captureException(err);
        });
        this.$snackbar({
          content: this.$t('Index.documentSendingError'),
          color: 'error',
          icon: '$error'
        });
      } finally {
        this.sendingDocument = false;
      }
    },
    discardDocument() {
      this.currentImages = [];
      this.$snackbar({ content: this.$t('Index.discardDocumentMessage'), color: 'info' });
    },
    setPreviewedImage(image) {
      if (image) {
        this.previewedImage = image;
      } else if (!image && this.currentImages.length > 0) {
        this.previewedImage = this.currentImages.pop();
      }
    },
  },
};
</script>

<style>
.container.fill-height > .row {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: unset;
}
.v-speed-dial__list {
  left: -100px !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  flex-wrap: wrap;
  width: unset !important;
}
.fixed-button-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
</style>
