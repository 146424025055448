<template>
  <v-row :style="{ 'height': currentImages ? '0' : '100%' }" class="align-center">
    <v-col v-if="!modulesLoaded" cols="12">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          {{ $t('Camera.loadingMessage')}}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="secondary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else
           cols="12"
           class="text-center"
           :style="{ 'display' : currentImages ? 'none' : 'block' }">
      <v-icon v-if="!currentImages"
              size="200px"
              @click="$refs.camera_input.click()">$camera</v-icon>
      <div v-if="!currentImages"
           class="text-uppercase title"
           style="font-size: 1.5rem !important;">{{ $t('Camera.captureInvoice')}}</div>
      <input
        id="cameraInput"
        ref="camera_input"
        style="display: none;"
        type="file"
        capture="environment"
        accept="image/*"
        name="cameraInput"
        @change="cameraInputChange"
      />
      <canvas ref="canvas_output" class="camera-canvas" />
    </v-col>
    <v-overlay :value="imageProcessing" opacity="0.75" class="text-center" z-index="205">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <div>{{ $t('Camera.imageInProcessing')}}</div>
    </v-overlay>
  </v-row>
</template>

<script>
import { uuid } from '../helpers/helpers';

export default {
  name: 'Camera',
  props: {
    currentImages: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.bus.$on('open-camera', () => {
      // eslint-disable-next-line no-restricted-globals
      this.openCamera();
    });
  },
  data() {
    return {
      loaded: true,
      image: null,
      imageProcessing: false,
      workerLoaded: false,
      worker: null,
      modulesLoaded: true
    };
  },
  methods: {
    openCamera() {
      this.$refs.camera_input.click();
    },
    async cameraInputChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      try {
        this.imageProcessing = true;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.image = new Image();
          this.image.onload = () => {
            this.setupCanvasAndEmitImage();
          }
          this.image.src = fileReader.result;
        }
        fileReader.readAsDataURL(file);
      } catch (e) {
        this.$sentry.withScope((scope) => {
          const { user } = this.$store.state.user;
          scope.setUser({ email: user.email });
          this.$sentry.captureException(e);
        });
        this.$snackbar({
          content: this.$t('Camera.imageProcessingError'),
          color: 'error'
        });
        this.$emit('image-to-preview', null);
      } finally {
        this.imageProcessing = false;
      }
    },
    setupCanvasAndEmitImage() {
      const maxSize = 1800;
      let { width, height } = this.image;
      if (this.image.width >= this.image.height && this.image.width > maxSize) {
        width = maxSize;
        height = this.image.height * (maxSize / this.image.width)
      } else if (this.image.height >= this.image.width && this.image.height > maxSize) {
        height = maxSize;
        width = this.image.width * (maxSize / this.image.height);
      }
      const outputCanvasContext = this.$refs.canvas_output.getContext('2d');
      outputCanvasContext.canvas.width = width;
      outputCanvasContext.canvas.height = height;
      outputCanvasContext.drawImage(this.image, 0, 0, width, height);
      this.$emit('image-to-preview',
        {
          dataURL: outputCanvasContext.canvas.toDataURL("image/jpeg", 0.6),
          id: uuid()
        });
      this.image = null;
      outputCanvasContext.clearRect(0, 0,
        outputCanvasContext.canvas.width,
        outputCanvasContext.canvas.height);
    }
  }
};
</script>

<style scoped>
.camera-canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
}
</style>
