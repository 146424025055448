<template>
  <v-overlay :value="overlay" opacity="0.8" z-index="205" ref="preview_overlay">
    <v-row class="pa-4" justify="center">
      <v-col cols="12">
        <div class="text-center">
          {{ documentTypes[options.documentType] }}: Page {{ currentPage }}
        </div>
      </v-col>
      <v-col cols="12">
        <v-col cols="12" sm="8" md="6" class="mx-auto">
          <v-img
            v-if="image"
            contain
            max-height="70vh"
            class="mx-auto"
            :src="image.dataURL">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-col>
      <v-col cols="12" class="justify-space-between d-flex">
        <v-btn
          rounded
          large
          color="primary"
          @click="$emit('new-page', image.id)"
        >
          <v-icon left>$add</v-icon>
          {{ $t('ImagePreview.newPageButton') }}
        </v-btn>
        <v-btn
          rounded
          large
          color="info darken-1"
          @click="$emit('send-image', image.id)"
        >
          <v-icon left>$send</v-icon>
          {{ $t('ImagePreview.sendPageButton') }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="justify-space-around d-flex mx-auto">
        <v-btn
          text
          small
          color="white"
          @click="showSettings = true"
        >
          <v-icon left>{{ settingsIcon }}</v-icon>
          {{ $t('ImagePreview.settingsButton') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="discardDialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          fixed
          top
          right
          v-on="on"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">{{ $t('DocumentGrid.discardDocument') }}</v-card-title>
        <v-card-text>{{ $t('DocumentGrid.discardDocumentDialogText') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="discardDialog = false">
            {{ $t('DocumentGrid.cancelButton') }}
          </v-btn>
          <v-btn color="green darken-1" text
                 @click="() => { this.closeOverlay(); this.discardDialog = false }"
          >{{ $t('DocumentGrid.discardButton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSettings" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" height="80px">
          <div class="d-flex" style="flex-direction: column">
            <div>
              {{ $t('DocumentOptions.title') }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="showSettings = false">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12">
              <v-radio-group
                v-model="options.documentType"
                :label="$t('DocumentOptions.selectDocumentType')"
                type="string"
              >
                <v-radio label="Received invoice" value="INV"></v-radio>
                <v-radio label="Issued invoice" value="IRN"></v-radio>
                <v-radio label="Other" value="OST"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mdiCog } from "@mdi/js";

export default {
  name: 'ImagePreview',
  props: {
    image: {
      type: Object,
      default: null
    },
    currentPage: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
    }
  },
  // setup(props) {
  //   watch(() => props.documentType, (selection, prevSelection) => {
  //     console.log(selection)
  //     console.log(prevSelection)
  //   })
  // },
  data() {
    return {
      overlayOpen: false,
      discardDialog: false,
      settingsIcon: mdiCog,
      showSettings: false,
      documentTypes: {
        INV: 'Received invoice',
        IRN: 'Issued invoice',
        OST: 'Other',
      }
    }
  },
  watch: {
    image(value) {
      this.overlay = !!value;
    },
    "options.documentType": function () {
      this.showSettings = false;
    }
  },
  computed: {
    overlay: {
      get() {
        return this.overlayOpen;
      },
      set(value) {
        this.overlayOpen = value;
        if (!value) this.$emit('preview-closed');
      }
    }
  },
  methods: {
    closeOverlay() {
      this.overlay = false;
      this.$emit('user-overlay-closed');
    }
  }
};
</script>

<style>
.v-overlay__content {
  width: 100% !important;
}
</style>
