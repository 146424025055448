export default async function generatePDF(imageDataArray) {
  const jsPDF = await import(/* webpackChunkName: "js-pdf" */'jspdf/dist/jspdf.node.js');
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF.default('p', 'mm', 'a4', true);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  imageDataArray.forEach((image, index) => {
    pdf.addImage(image, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
    if (index !== imageDataArray.length - 1) {
      pdf.addPage();
    }
  });
  return pdf.output('blob');
}
